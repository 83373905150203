import React from 'react';
import { CloseIcon } from '@zola/zola-ui/src/components/SvgIcons';
import { Container, CloseIconContainer } from './FilterPill.styles';

type FilterPillProps = {
  label: string;
  onClose: () => void;
};

const FilterPill = ({ label, onClose }: FilterPillProps): JSX.Element => {
  return (
    <Container>
      {label}
      <CloseIconContainer onClick={onClose}>
        <CloseIcon width={10} />
      </CloseIconContainer>
    </Container>
  );
};

export default FilterPill;
