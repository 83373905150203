import { experimentStates } from '@zola-helpers/client/dist/es/experiments';
import experimentFlagsHelper from '@zola-helpers/client/dist/es/util/experimentFlagsHelper';
import experimentsFlagsHelperSSR from '@zola-helpers/server/dist/es/experiments';

// LogRocket Keys
export const LOGROCKET_PLANNING_ONBOARD_FLAG = 'logrocket-planning-onboard';
export const LOGROCKET_WEBSITE_ONBOARD = 'logrocket-website-onboard';
export const SUPER_LINK_LOG_ROCKET = 'magic-link-log-rocket'; // CE-847 https://newamsterdamlabs.atlassian.net/browse/CE-847
export const LOGROCKET_INVITES_PDP = 'logrocket-invites-pdp'; // COMMERCE-585 https://newamsterdamlabs.atlassian.net/browse/COMMERCE-585
export const LOGROCKET_YOUR_WEDDING_V2 = 'logrocket-your-wedding-v2';

// Experiment Keys
export const REGISTRY_GIFT_GIVER_TILE_HEIGHT = 'registry-gift-giver-tile-height'; // https://newamsterdamlabs.atlassian.net/browse/COMMERCE-929
export const REGISTRY_GIFT_GIVER_PDP_BADGE = 'registry-gift-giver-pdp-badge'; // https://newamsterdamlabs.atlassian.net/browse/COMMERCE-931
export const HOME_NEWNESS = 'home-newness';
export const DESKTOP_TO_APP_ENABLED = 'desktop-to-app-enabled'; // https://newamsterdamlabs.atlassian.net/browse/CE-1417
export const DSTD_WEDDING_WEBSITE_ENTRYPOINT = 'dstd-wedding-website-entrypoint-ii'; // https://newamsterdamlabs.atlassian.net/browse/COMMERCE-1133
export const HDYHAU_SUBWAY = 'hdyhau-subway';
export const DSTD_NAV_ENTRY = 'dstd-navigation-entry';
export const CHAR_COUNT_INCREASE = 'website-char-count-increase'; // https://newamsterdamlabs.atlassian.net/browse/CE-1876
export const RECAPTCHA_REFACTOR = 'recaptcha-refactor'; // https://newamsterdamlabs.atlassian.net/browse/CE-2186
export const PAPER_PLP_PRICE_DISPLAY = 'paper-plp-price-display'; // https://newamsterdamlabs.atlassian.net/browse/COMMERCE-1941
export const ONBOARDING_GP_MOBILE_JAN_2023 = 'onboarding-gp-mobile-jan-2023'; // https://newamsterdamlabs.atlassian.net/browse/CE-2908
export const ONBOARDING_GP_MOBILE_DROP_STEPS = 'onboard-gp-mobile-drop-extra-steps';
export const PAPER_PLP_SALE_EVENT = 'paper-invite-ty-sale-event';
export const EXTERNAL_REGISTRIES_SYNCHING_MESSAGING = 'external-registries-synching-messaging';
export const CORE_LOGROCKET_MANAGE_WEBSITE = 'core-logrocket-manage-website'; // https://newamsterdamlabs.atlassian.net/browse/CE-3769
export const ULP_TXT_ONLY_PROMO_BANNER = 'ulp-txt-only-promo-banner'; // https://newamsterdamlabs.atlassian.net/browse/CE-3805
export const WW_REGISTRY_REDESIGN = 'ww-to-registry-redesign'; // https://newamsterdamlabs.atlassian.net/browse/CE-3818
export const CORE_CONTACT_COLLECTOR_REDESIGN = 'core-contact-collector-redesign';
export const WEBSITE_SIGNUP_LANDING_V2 = 'website-signup-landing-v2'; // https://newamsterdamlabs.atlassian.net/browse/CE-3860
export const PAPER_ADD_ONS = 'paper-add-ons';
export const PAPER_ULP_STATIC_CAROUSEL = 'paper-ulp-static-carousel'; // https://newamsterdamlabs.atlassian.net/browse/PE-217
export const PAPER_PLP_FILTER_BUBBLES = 'paper-plp-filter-bubbles'; // https://newamsterdamlabs.atlassian.net/browse/PE-197
export const PAPER_FREE_SAMPLES_SECONDARY_NAV = 'paper-free-samples-secondary-nav'; // https://newamsterdamlabs.atlassian.net/browse/PE-223

// Seasonal flags
export const HOLIDAY_CARDS = 'holiday-cards';

// Promo flags

const FALLBACK_VARIATIONS = {
  // LogRocket Flags
  [LOGROCKET_PLANNING_ONBOARD_FLAG]: experimentFlagsHelper.createExperimentFlags(),
  [LOGROCKET_WEBSITE_ONBOARD]: experimentFlagsHelper.createExperimentFlags(),
  [SUPER_LINK_LOG_ROCKET]: experimentFlagsHelper.createExperimentFlags(),
  [LOGROCKET_INVITES_PDP]: experimentFlagsHelper.createExperimentFlags(),
  [LOGROCKET_YOUR_WEDDING_V2]: experimentFlagsHelper.createExperimentFlags(),
  // Experiment Flags
  [HOME_NEWNESS]: experimentFlagsHelper.createExperimentFlags(),
  [REGISTRY_GIFT_GIVER_TILE_HEIGHT]: experimentFlagsHelper.createExperimentFlags(),
  [REGISTRY_GIFT_GIVER_PDP_BADGE]: experimentFlagsHelper.createExperimentFlags(),
  [DESKTOP_TO_APP_ENABLED]: experimentFlagsHelper.createExperimentFlags(),
  [DSTD_WEDDING_WEBSITE_ENTRYPOINT]: experimentFlagsHelper.createExperimentFlags(),
  [HDYHAU_SUBWAY]: experimentFlagsHelper.createExperimentFlags(),
  [DSTD_NAV_ENTRY]: experimentFlagsHelper.createExperimentFlags(),
  [RECAPTCHA_REFACTOR]: experimentFlagsHelper.createExperimentFlags(),
  [PAPER_PLP_PRICE_DISPLAY]: experimentFlagsHelper.createExperimentFlags(),
  [ONBOARDING_GP_MOBILE_JAN_2023]: experimentFlagsHelper.createExperimentFlags(),
  [ONBOARDING_GP_MOBILE_DROP_STEPS]: experimentFlagsHelper.createExperimentFlags(),
  [PAPER_PLP_SALE_EVENT]: experimentFlagsHelper.createExperimentFlags(),
  [EXTERNAL_REGISTRIES_SYNCHING_MESSAGING]: experimentFlagsHelper.createExperimentFlags(),
  [CORE_LOGROCKET_MANAGE_WEBSITE]: experimentFlagsHelper.createExperimentFlags(),
  [ULP_TXT_ONLY_PROMO_BANNER]: experimentFlagsHelper.createExperimentFlags(), // experiment under SSR
  [WW_REGISTRY_REDESIGN]: experimentFlagsHelper.createExperimentFlags(),
  [CORE_CONTACT_COLLECTOR_REDESIGN]: experimentFlagsHelper.createExperimentFlags(),
  [HOLIDAY_CARDS]: experimentFlagsHelper.createExperimentFlags(),
  [PAPER_ADD_ONS]: experimentFlagsHelper.createExperimentFlags(),
  [PAPER_ULP_STATIC_CAROUSEL]: experimentFlagsHelper.createExperimentFlags(),
  [PAPER_PLP_FILTER_BUBBLES]: experimentFlagsHelper.createExperimentFlags(),
  [PAPER_FREE_SAMPLES_SECONDARY_NAV]: experimentFlagsHelper.createExperimentFlags(),
};

export const experimentFlags = experimentFlagsHelper.makeExperimentFlags(FALLBACK_VARIATIONS);
export default experimentFlags;

export const experimentFlagsSSR = experimentsFlagsHelperSSR.makeExperimentFlags(
  FALLBACK_VARIATIONS
);

/**
 * This universalExperimentFlags is used in the client and server.
 * But if you are using it on server, please be remember to call it again on client to trigger the tracking event.
 */
export const universalExperimentFlags =
  typeof window === 'undefined' ? experimentFlagsSSR : experimentFlags;

const { NOT_IN_TEST, CONTROL } = experimentStates;

/**
 * @function isInExperiment
 *
 * Helper function to check if variant is an experiment
 * Note: this will NOT work for an experiment that is a release ramp (i.e. experiment with only 1 variant)
 *
 * @param {string} variant
 *
 * @returns {boolean}
 */
export const isInExperiment = (variant: string): boolean =>
  variant !== 'TEST-0' && variant !== 'NOT_IN_TEST' && /^TEST-[1-9]$/.test(variant);

/**
 * Is the user in the experiment and are they the control group
 */
export const isControl = (variant: string): boolean => variant === CONTROL;

/**
 * Is the in the experiment and are they _not_ the control group.
 * If you have more than one variant (ie, and A/B/C test) instead
 * of a single variant (classic A/B test) you will need to check
 * the getExperimentVariation directly as you would have values such
 * as NOT_IN_TEST, TEST-0, TEST-1, and TEST-2.
 */
export const isUnderTest = (variant: string): boolean =>
  variant !== CONTROL && variant !== NOT_IN_TEST;
