import React from 'react';
import { useAppSelector } from 'reducers/index';
import { getRegistryCollections } from 'selectors/registryCollections/registryCollectionsSelectors';
import { useWebsiteThemeContext } from 'components/publicWebsiteV2/context';
import FilterPill from './FilterPill';
import { PillContainer } from './FilterPillContainer.styles';

type FilterPillContainerProps = {
  onClose: (facetKey: string, facetValue: string) => void;
  onCloseAll: () => void;
};
const FilterPillContainer = ({ onClose, onCloseAll }: FilterPillContainerProps): JSX.Element => {
  const registryCollections = useAppSelector(getRegistryCollections);

  const { facets = [], selectedFacetValues } = registryCollections;
  const {
    state: {
      components: { CmsEntityBodyLink },
    },
  } = useWebsiteThemeContext();

  const atLeastOneFacetApplied =
    Object.keys(selectedFacetValues).filter(f => selectedFacetValues[f].length > 0).length > 0;

  return (
    <PillContainer>
      {Object.keys(selectedFacetValues).map(selectedFacetKey =>
        selectedFacetValues[selectedFacetKey].map((selectedFacetValue: string) => {
          const facetObject = facets.find(f => f.key === selectedFacetKey);
          const { value: optionValue, label: optionLabel } =
            (facetObject?.options || []).find(o => o.value === selectedFacetValue) || {};
          if (optionLabel && optionValue) {
            return (
              <FilterPill
                key={`filter-pill-${facetObject?.key}-${optionLabel}`}
                label={optionLabel}
                onClose={() => {
                  onClose(selectedFacetKey, optionValue);
                }}
              />
            );
          }
          return null;
        })
      )}
      {atLeastOneFacetApplied && (
        <CmsEntityBodyLink role="button" onClick={onCloseAll}>
          Clear all
        </CmsEntityBodyLink>
      )}
    </PillContainer>
  );
};

export default FilterPillContainer;
